import { graphql, StaticQuery } from "gatsby";
import {Content} from "../../components";
import { prepareContent } from "./helpers";
import React from "react";

export default ({
  showExcerpt,
  name,
  ...rest
}: {
  name: string,
  showExcerpt: boolean
}) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "about-collector" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 350)
              html
              id
              fields {
                slug
              }
              frontmatter {
                title
                photo {
                  src
                  alt
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {transformers} =rest;
      return (
          <Content name={name} {...prepareContent(data, showExcerpt,transformers)} {...rest} />
      );
    }}
  />
);


