import React from "react";
import {
  CollectorContent,
  ModernContent,
  TraditionalContent
} from "../../page-components/about";
import { Layout } from "../../components";
import { getAdminToolsForContent,userHasRole } from "../../util";
import PropTypes from "prop-types";
import { withAuth } from "../../HOC";

const Component = () => {
  const isAuth = userHasRole('admin')
  return (
    <Layout>
      <CollectorContent
        actions={[getAdminToolsForContent("collector", isAuth)]}
        isContained
        name="about-collector"
        wrap={"wrap"}
        isLeft
        transformers='q_auto,dpr_auto,c_pad,g_auto/'
      />

      <TraditionalContent
        actions={[getAdminToolsForContent("traditional", isAuth)]}
        useAux
        name="about-traditional"
        wrap={"wrap"}
        isRight
      />

      <ModernContent
        actions={[getAdminToolsForContent("modern", isAuth)]}
        useAux
        name="about-modern"
        wrap={"wrap"}
        isLeft
      />
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
