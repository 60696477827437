import CollectorContent from './collector';
import ModernContent from './modern';
import TraditionalContent from './traditional';
import CollectionContent from './intro';

export {
  CollectorContent,
  TraditionalContent,
  ModernContent,
  CollectionContent
}