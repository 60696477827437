// @flow

import type { IGraphqlContent } from "../../definitions";
import { prepareImage } from "../../util";

const TRANSFORMERS = "q_auto,dpr_auto,c_pad,g_auto,e_sharpen/";
const BASEURL = process.env.CLD_IMG_BASEURL;
const VERSION = process.env.CLD_IMG_VERSION;

export const prepareContent = (
  content: IGraphqlContent,
  showExcerpt: boolean,
  transformers: string = TRANSFORMERS
) => {
  const item = content.allMarkdownRemark.edges[0].node;
  const {
    title,
    photo: { src, alt }
  } = item.frontmatter;
  const auxSrc =
    item.frontmatter &&
    item.frontmatter.auxPhoto &&
    item.frontmatter.auxPhoto.auxSrc;
  const body = showExcerpt ? item.excerpt : item.html;
  return {
    src: prepareImage(src, transformers),
    title,
    body,
    auxSrc: prepareImage(auxSrc, transformers)
  };
};
